import { Icon, type IconProps } from '@chakra-ui/icons';

export const PerpsStats = ({ width = '88px', height = '15px', ...props }: IconProps) => (
  <Icon width={width} height={height} viewBox="0 0 88 15" fill="none" {...props}>
    <path
      d="M18.2356 0.999999H20.8756L16.4156 15H13.6556L10.7356 4.52L7.83556 15H5.07556L0.615557 0.999999H3.25556L6.45556 12.12L9.55556 0.999999H11.9356L15.0356 12.12L18.2356 0.999999ZM25.2038 13H28.8238C29.2038 13 29.5238 12.7 29.5238 12.3V6.18H25.2038C24.5638 6.18 24.0438 6.68 24.0438 7.32V11.84C24.0438 12.5 24.5638 13 25.2038 13ZM27.7838 15H24.5838C23.0038 15 21.7438 13.68 21.7438 12.16V7C21.7438 5.42 23.0638 4.16 24.5838 4.16H31.8238V15H29.5238V13.52L27.7838 15ZM40.9852 13V15H38.2052C36.6652 15 35.4052 13.8 35.4052 12.22V6.18H33.5252V4.16H35.4052V1.78H37.7252V4.16H41.0052V6.18H37.7252V11.88C37.7252 12.54 38.1652 13 38.8252 13H40.9852ZM49.8018 15H45.4818C43.9618 15 42.6618 13.74 42.6618 12.16V7C42.6618 5.46 43.9018 4.16 45.4818 4.16H49.8018C51.3418 4.16 52.6418 5.42 52.6418 7V8.02H50.3418V7.32C50.3418 6.68 49.8218 6.18 49.1818 6.18H46.1218C45.4818 6.18 44.9618 6.68 44.9618 7.32V11.84C44.9618 12.5 45.4818 13 46.1218 13H49.1818C49.8218 13 50.3418 12.5 50.3418 11.84V11.16H52.6418V12.16C52.6418 13.74 51.3418 15 49.8018 15ZM57.3112 6.86V15H55.0112V0.279999H57.3112V5.56L58.9912 4.16H62.4912C64.0312 4.16 65.3312 5.4 65.3312 7V15H63.0112V7.32C63.0112 6.68 62.5112 6.18 61.8712 6.18H58.0112C57.6312 6.18 57.3112 6.46 57.3112 6.86ZM74.4209 6.14H71.1209C70.4809 6.14 69.9809 6.64 69.9809 7.3V8.54H75.5209V7.24C75.5209 6.6 75.0609 6.14 74.4209 6.14ZM74.9409 15H70.5209C68.9609 15 67.7009 13.72 67.7009 12.16V7C67.7009 5.42 68.9609 4.16 70.5209 4.16H75.0009C76.5609 4.16 77.7809 5.4 77.7809 6.94V10.32H69.9809V11.86C69.9809 12.52 70.4809 13.02 71.1209 13.02H74.3609C75.0009 13.02 75.5209 12.52 75.5209 11.86V11.58H77.7809V12.3C77.7809 13.88 76.4809 15 74.9409 15ZM82.4479 6.86V15H80.1479V4.16H82.4479V5.52L84.1279 4.16H87.5479V6.18H83.1479C82.7679 6.18 82.4479 6.46 82.4479 6.86Z"
      fill="white"
    />
  </Icon>
);

import { Icon, type IconProps } from '@chakra-ui/react';

export const WreckedIcon = ({
  width = '40px',
  height = '40px',
  color = '#FEFEFF',
  ...props
}: IconProps) => {
  return (
    <Icon width={width} height={height} viewBox="0 0 40 40" fill="none" color={color} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8938 3.70361C11.5498 3.70361 4.59918 9.98142 4.59918 17.9469C4.59918 21.7743 6.2224 25.2356 8.82718 27.7799C9.46854 28.4064 9.818 29.142 9.818 29.8408V31.7314C9.818 33.942 11.5333 35.8661 13.8052 35.8661H16.4146H16.7764H17.7924H18.1542H21.6334H21.9952H23.0112H23.373H25.9824C28.2544 35.8661 29.9696 33.942 29.9696 31.7314V29.8408C29.9696 29.142 30.3191 28.4065 30.9604 27.7803C33.5653 25.2371 35.1884 21.7791 35.1884 17.9469C35.1884 9.98142 28.2378 3.70361 19.8938 3.70361ZM7.35474 17.9469C7.35474 11.7029 12.8641 6.45917 19.8938 6.45917C26.9236 6.45917 32.4329 11.7029 32.4329 17.9469C32.4329 20.9746 31.1554 23.7389 29.0354 25.8087C28.0126 26.8073 27.2141 28.2139 27.2141 29.8408V31.7314C27.2141 32.5649 26.5917 33.1105 25.9824 33.1105H24.7508V31.7314C24.7508 30.5374 23.8129 29.4347 22.5032 29.4347C21.1935 29.4347 20.2556 30.5374 20.2556 31.7314V33.1105H19.532V31.7314C19.532 30.5374 18.5941 29.4347 17.2844 29.4347C15.9747 29.4347 15.0368 30.5374 15.0368 31.7314V33.1105H13.8052C13.1959 33.1105 12.5736 32.5649 12.5736 31.7314V29.8408C12.5736 28.2139 11.775 26.8073 10.7526 25.8087C8.63236 23.7377 7.35474 20.9699 7.35474 17.9469ZM14.675 23.4607C16.594 23.4607 18.1542 21.8123 18.1542 19.7848C18.1542 17.7574 16.594 16.109 14.675 16.109C12.756 16.109 11.1958 17.7574 11.1958 19.7848C11.1958 21.8123 12.756 23.4607 14.675 23.4607ZM21.6334 19.7848C21.6334 21.8123 23.1936 23.4607 25.1126 23.4607C27.0316 23.4607 28.5918 21.8123 28.5918 19.7848C28.5918 17.7574 27.0316 16.109 25.1126 16.109C23.1936 16.109 21.6334 17.7574 21.6334 19.7848Z"
        fill="currentColor"
      />
    </Icon>
  );
};

import { Icon, type IconProps } from '@chakra-ui/icons';

export const WalletIcon = ({ width = '16px', height = '14px', ...props }: IconProps) => {
  return (
    <Icon width={width} height={height} viewBox="0 0 16 14" color="#00D1FF" {...props}>
      <path
        d="M4.58154 3.2701C4.23637 3.2701 3.95654 3.54993 3.95654 3.8951C3.95654 4.24028 4.23637 4.5201 4.58154 4.5201H8.31012C8.65529 4.5201 8.93512 4.24028 8.93512 3.8951C8.93512 3.54993 8.65529 3.2701 8.31012 3.2701H4.58154Z"
        fill="currentColor"
      />
      <path
        d="M11.972 6.10498C11.6268 6.10498 11.347 6.38481 11.347 6.72998C11.347 7.07516 11.6268 7.35498 11.972 7.35498H12.1873C12.5324 7.35498 12.8123 7.07516 12.8123 6.72998C12.8123 6.38481 12.5324 6.10498 12.1873 6.10498H11.972Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2918 8.63106L15.2916 8.64472V9.34161C15.2916 11.6883 13.3892 13.5907 11.0426 13.5907H5.07331C2.72664 13.5907 0.824219 11.6883 0.824219 9.34161V4.38508C0.824219 2.0384 2.72664 0.135986 5.07331 0.135986H11.0426C13.3892 0.135986 15.2916 2.0384 15.2916 4.38508V4.90016L15.2918 4.91382L15.2916 4.92747V8.61741L15.2918 8.63106ZM5.07331 1.38599C3.41699 1.38599 2.07422 2.72876 2.07422 4.38508V9.34161C2.07422 10.9979 3.41699 12.3407 5.07331 12.3407H11.0426C12.6989 12.3407 14.0416 10.9979 14.0416 9.34161V9.25606H11.871C10.4998 9.25522 9.38758 8.14453 9.38674 6.77282C9.38674 5.40047 10.4998 4.28966 11.8706 4.28882H14.0401C13.9893 2.67705 12.6667 1.38599 11.0426 1.38599H5.07331ZM11.8714 8.00606H14.0416V5.53882H11.8712C11.1891 5.53932 10.6369 6.09162 10.6367 6.77223C10.6372 7.45355 11.1895 8.00564 11.8714 8.00606Z"
        fill="currentColor"
      />
    </Icon>
  );
};
